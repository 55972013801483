import { graphql } from 'gatsby';
import * as React from 'react';
import Seo from 'gatsby-plugin-wpgraphql-seo';
import Layout from '~/components/Layout';
import HeroHeading from '~/components/organisms/HeroHeading';
import FadeInWhenVisible from '~/components/FadeInWhenVisible';
import LegalNav from '~/components/molecules/LegalNav';
import CustomSEO from '~/components/seo/CustomSEO';

export default function legal({ data: { wpPage } }) {
  const { title, content, day, month, year } = wpPage;

  const hero = {
    "textCard" : {
      "heading" : {
        "type": "h1",
        "text": "Legal"
      },
      "alignText" : "center"
    }
  }

  return (
    <Layout>
      <Seo post={wpPage} />
      <CustomSEO seo={wpPage?.seo} />
      <HeroHeading {...hero} />
      <section className="mb-20 md:mb-36">
        <div className="container">
          <div className="grid grid-cols-12">
            <FadeInWhenVisible className="col-span-12 lg:col-span-3 lg:border-r border-r-navy border-opacity-10 mb-10 lg:mb-0">
              <LegalNav />
            </FadeInWhenVisible>

            <FadeInWhenVisible className="col-span-12 lg:col-span-8 lg:col-start-5">
              <header className="flex flex-col md:flex-row space-y-8 md:space-y-0 md:items-center mb-14">
                <div className="md:ml-auto text-navy text-opacity-40 md:order-1">Last updated: {day} {month} {year}</div>
                <h2 className="text-h2">{title}</h2>
              </header>
              <div className="prose" dangerouslySetInnerHTML={{__html: content}} />
            </FadeInWhenVisible>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query legalPageQuery($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      id
      content
      date
      ...SEO
      day: date(formatString:"DD")
      month: date(formatString:"MMM")
      year: date(formatString:"YY")
    }
  }
`
