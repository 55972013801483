import { Link } from 'gatsby';
import React from 'react'
import useLegalAssets from '~/hooks/useLegalAssets';

export default function LegalNav() {

  const { legalNav } = useLegalAssets();
  return (
    <nav>
      <ul>
        {legalNav.menuItems.nodes.map((item, i) => (
          <li key={item.id} className="mb-2 ">
            <Link to={item.url} className="text-navy text-opacity-30 font-medium text-xl hover:text-opacity-100 transition-colors duration-200 ease-in-out" activeClassName="!text-opacity-100">{item.label}</Link>
          </li>
        ))}
      </ul>
    </nav>
  )
}
